import React from "react";
import styled from "styled-components";
import { spectrum } from "styles/theme";
import { ArrowUpRight } from "lucide-react";

type ImageContainerProps = {
  backgroundColor: string;
};

const CaseStudyContainer = styled.div`
  &:hover {
    cursor: pointer;
    background-color: ${spectrum.slate0};
  }
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;
const ImageContainer = styled.div<ImageContainerProps>`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : "")};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const TitleContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type CaseStudyProps = {
  logo: string;
  title: string;
  slug: string;
  companyColor: string;
};

const navigateToCaseStudy = (slug: string) => {
  window.open(`https://merge.dev/case-studies/${slug}`, "_blank");
};
const CaseStudy = ({ logo, title, slug, companyColor }: CaseStudyProps) => {
  return (
    <CaseStudyContainer
      className="d-flex rounded-lg"
      onClick={() => {
        navigateToCaseStudy(slug);
      }}
    >
      <ImageContainer backgroundColor={companyColor} className="d-flex align-items-center p-5">
        <img src={logo} width="38" height="33" />
      </ImageContainer>
      <div className="d-flex flex-column flex-grow-1 pl-3 pr-3 pt-2 pb-2">
        <div className="d-flex align-items-center">
          <div className="text-gray-50 text-xs">CASE STUDY</div>
          <div className="ml-auto">
            <ArrowUpRight size={12} />
          </div>
        </div>
        <TitleContainer className="text-sm font-semibold">{title}</TitleContainer>
      </div>
    </CaseStudyContainer>
  );
};

export default CaseStudy;
