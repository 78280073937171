import styled from "styled-components";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import React, { useCallback, useEffect, useState } from "react";
import { Accordion, Card, Form } from "react-bootstrap";
import { ThumbsUp, ThumbsDown, CheckCircle } from "lucide-react";
import { fetchWithoutAuth } from "api_client";

interface FeedbackButtonInterface {
  use_case_workflow_id: string;
}

interface CustomToggleProps {
  onClick: () => void;
  hasSubmitted: boolean;
}

interface ButtonSelectProps {
  hasSelectedButton: boolean;
}

const PaddingMergeAction = styled(Button)<ButtonSelectProps>`
  padding: 4px 12px 4px 10px !important;
  font-size: 14px !important;
  line-height: 22px !important;
  &&& {
    background-color: ${(props) =>
      props.hasSelectedButton ? "var(--gray20)" : "#FFFFFF"} !important;
    &:hover {
      background-color: ${(props) =>
        props.hasSelectedButton ? "var(--gray20)" : "var(--gray0)"} !important;
    }
  }
`;

const FeedbackContainer = styled.div`
  row-gap: 16px;
`;

interface WorkflowMetricTracker {
  use_case_workflow: string;
  reaction: number;
  comment?: string;
  referring_url?: string;
}

const FeedbackButton = ({ use_case_workflow_id }: FeedbackButtonInterface) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [likeSelected, setLikeSelected] = useState<boolean>(false);
  const [dislikeSelected, setDislikeSelected] = useState<boolean>(false);
  const [referringURL, setReferringURL] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setReferringURL(document.referrer);
  }, []);

  const handleClick = useCallback(() => {
    if (likeSelected || dislikeSelected) {
      return;
    }
    setIsOpen(!isOpen);
  }, [isOpen]);

  function WorkflowMetricTracker(props: WorkflowMetricTracker) {
    const { use_case_workflow, reaction, comment, referring_url } = props;
    fetchWithoutAuth({
      path: "/marketing/workflow-metrics",
      method: "POST",
      body: {
        use_case_workflow: use_case_workflow,
        reaction: reaction,
        comment: comment,
        referring_url: referring_url,
      },
      onResponse: () => {},
    });
  }

  const submitFeedback = () => {
    const reaction = likeSelected ? 1 : dislikeSelected ? 2 : 0;
    WorkflowMetricTracker({
      use_case_workflow: use_case_workflow_id,
      reaction,
      comment,
      referring_url: referringURL,
    });
    setHasSubmitted(true);
    setIsOpen(false);
  };

  const CustomToggle: React.FC<CustomToggleProps> = ({ onClick, hasSubmitted }) => {
    return (
      <FeedbackContainer className="d-flex align-items-center justify-content-between flex-wrap py-5 px-6">
        {hasSubmitted ? (
          <div className="d-flex align-items-center justify-content-center">
            <CheckCircle className="text-teal-50 mr-2 flex-shrink-0" size={16} />{" "}
            <b>Thanks for your feedback! Your response will help us improve this page.</b>
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center white-space-nowrap">
              <h6 className="mr-6 mb-0">Was this page helpful?</h6>
              <PaddingMergeAction
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                onClick={() => {
                  onClick();
                  setLikeSelected(true);
                  setDislikeSelected(false);
                }}
                leftIcon={<ThumbsUp size={12} />}
                className="mr-3"
                hasSelectedButton={likeSelected}
              >
                Yes
              </PaddingMergeAction>
              <PaddingMergeAction
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                onClick={() => {
                  onClick();
                  setLikeSelected(false);
                  setDislikeSelected(true);
                }}
                leftIcon={<ThumbsDown size={12} />}
                className="mr-3"
                hasSelectedButton={dislikeSelected}
              >
                No
              </PaddingMergeAction>
            </div>
            <div className="d-flex flex-row align-items-center white-space-nowrap text-sm">
              Still running into issues? Visit our&nbsp;
              <a href="https://help.merge.dev/en/" target="_blank">
                Help Center
              </a>
              .
            </div>
          </>
        )}
      </FeedbackContainer>
    );
  };

  return (
    <Accordion className="w-100 mb-0" activeKey={isOpen ? "0" : undefined}>
      <Card className="mb-0 bg-sim-blue">
        <CustomToggle hasSubmitted={hasSubmitted} onClick={handleClick} />
        {!hasSubmitted ? (
          <Accordion.Collapse eventKey="0">
            <div className="px-6 pb-5">
              <div className="mb-2 d-flex justify-content-between">
                <div>Share your feedback:</div>
                <div className="text-gray-50 text-xs">Optional</div>
              </div>
              <Form.Control
                className="border-none shadow-md mb-4"
                placeholder="Please let us know what was or wasn't helpful about this page."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Button variant={ButtonVariant.PrimaryBlack} size="sm" onClick={submitFeedback}>
                Submit
              </Button>
            </div>
          </Accordion.Collapse>
        ) : null}
      </Card>
    </Accordion>
  );
};

export default FeedbackButton;
