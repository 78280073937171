import React from "react";
import { IndustryType } from "types/UseCaseWorkflowTypes";
import { Globe } from "lucide-react";

type SupportedIndustriesProps = {
  industries: IndustryType[];
};

const SupportedIndustries = ({ industries }: SupportedIndustriesProps) => {
  return (
    <div className="py-3 px-4 bg-gray-0 rounded-lg border border-gray-10">
      <div className="d-flex align-items-center">
        <Globe size={14} />
        <span className="ml-2.5 text-md font-semibold">Industries</span>
      </div>
      <div className="d-flex flex-column mt-0.5 text-xs text-slate-90">
        {industries.map((industry) => {
          return <div>{industry.name}</div>;
        })}
      </div>
    </div>
  );
};
export default SupportedIndustries;
