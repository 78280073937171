import React from "react";
import styled from "styled-components";
import CaseStudy from "./CaseStudy";
import { CaseStudyType } from "types/UseCaseWorkflowTypes";

const CaseStudiesContainer = styled.div`
  gap: 24px;
`;

type CaseStudiesProps = {
  caseStudies: CaseStudyType[];
};
const CaseStudies = ({ caseStudies }: CaseStudiesProps) => {
  return (
    <CaseStudiesContainer className="mt-4 d-flex flex-column">
      <h4 className="mb-0 font-semibold">See this use case in action:</h4>
      {caseStudies.map((caseStudy) => {
        return (
          <CaseStudy
            logo={caseStudy.logo}
            title={caseStudy.title}
            slug={caseStudy.slug}
            companyColor={caseStudy.company_color}
          />
        );
      })}
    </CaseStudiesContainer>
  );
};
export default CaseStudies;
