import React, { useState, useEffect } from "react";

interface IStepImageComponentProps {
  src: string;
}

interface IDimensions {
  width?: number;
  height?: number;
}

const StepImageComponent: React.FC<IStepImageComponentProps> = ({ src }) => {
  const [dimensions, setDimensions] = useState<IDimensions>({});
  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      setDimensions({
        width: image.width / 4,
        height: image.height / 4,
      });
      setImageLoaded(true);
    };
  }, [src]);

  if (isImageLoaded && dimensions.width && dimensions.height) {
    return <img src={src} width={dimensions.width} height={dimensions.height} />;
  }

  return null;
};

export default StepImageComponent;
