import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FeatureCallout from "./FeatureCallout";
import { FeatureCalloutType } from "types/UseCaseWorkflowTypes";
import StepImageComponent from "./StepImageComponent";

const FeatureCalloutContainer = styled.div`
  gap: 16px;
`;

const UseStepContainer = styled.div`
  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &&& {
    code {
      background: var(--gray0);
      padding: 0px 5px;
      border-radius: 3px;
      font-weight: 400;
      color: var(--gray90);
      font-size: 13px;
      line-height: 24px;
    }
  }
`;

const ImageContainer = styled.div`
  && {
    @media (max-width: 576px) {
      margin: auto;
      margin-top: 16px;
    }
    margin-left: auto;
  }
`;

const ReferenceWorkflowStepImageContainer = styled.div`
  position: relative;
  bottom: 8px;
`;

const ReferenceWorkflowStepImageLink = styled.a`
  position: relative;
  bottom: 4px;
`;

type UseCaseStepProps = {
  name: string;
  content: string;
  featureCallouts: FeatureCalloutType[];
  stepImage: any;
  stepNumber: number;
  reference_workflow_name: string;
  reference_workflow_slug: string;
};

const UseCaseStep = ({
  name,
  content,
  featureCallouts,
  stepImage,
  stepNumber,
  reference_workflow_name,
  reference_workflow_slug,
}: UseCaseStepProps) => {
  const stepImageReferenceWorkflow: string = "4ab0220f-cd1d-4acb-8af6-ba98c4c86f79";
  const stepImageHasReferenceWorkflow: boolean =
    reference_workflow_name?.length > 0 &&
    reference_workflow_slug?.length > 0 &&
    stepImage?.id === stepImageReferenceWorkflow;

  return (
    <div className="mb-12">
      <UseStepContainer className="d-flex align-items-center">
        <div>
          <div className="text-lg font-semibold">
            {stepNumber}. <span className="ml-4">{name}</span>
          </div>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
        </div>
        {stepImage && (
          <ImageContainer className="d-flex align-self-start">
            <div className="d-flex align-items-center flex-column ml-10">
              <StepImageComponent src={stepImage?.image} />
              {stepImageHasReferenceWorkflow && (
                <ReferenceWorkflowStepImageContainer className="d-flex flex-column align-items-center">
                  <div className="text-slate-90 text-xs font-medium">See Use Case</div>
                  <ReferenceWorkflowStepImageLink
                    href={`/use-cases/${reference_workflow_slug}`}
                    target="_blank"
                    className="text-sm font-semibold"
                  >
                    {reference_workflow_name}
                  </ReferenceWorkflowStepImageLink>
                </ReferenceWorkflowStepImageContainer>
              )}
            </div>
          </ImageContainer>
        )}
      </UseStepContainer>
      {featureCallouts && (
        <FeatureCalloutContainer className="mt-6 d-flex flex-column gap-4">
          {featureCallouts.map(({ content }: any) => {
            return <FeatureCallout content={content} />;
          })}
        </FeatureCalloutContainer>
      )}
    </div>
  );
};

export default UseCaseStep;
