import React from "react";
import styled from "styled-components";
import { Rocket } from "lucide-react";
import { spectrum } from "styles/theme";

type FeatureCalloutProps = {
  content: string;
};

const FeatureCalloutContainer = styled.div`
  background-color: ${spectrum.blue0};
  font-size: 12px;
  line-height: 20px !important;
`;
const FeatureCallout = ({ content }: FeatureCalloutProps) => {
  return (
    <FeatureCalloutContainer className="$palette-blue-bg pt-3 pb-3 pl-4 pr-4 d-flex align-items-center rounded-lg">
      <div>
        <Rocket color={spectrum.blue40} size={12} />
      </div>
      <div
        className="ml-3"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    </FeatureCalloutContainer>
  );
};

export default FeatureCallout;
