import React from "react";
import { UseCaseCTAType } from "types/UseCaseWorkflowTypes";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";
type UseCaseCTAProps = {
  cta: UseCaseCTAType;
};

const UseCaseCTA = ({ cta }: UseCaseCTAProps) => {
  const { title, subtitle, button_text: buttonText, url } = cta;
  const openInNewTab = () => {
    window.open(String(url), "_blank");
  };
  const stringButtonText = String(buttonText);
  return (
    <div className="p-5 pt-4 rounded-xl border border-gray-10 mb-9">
      <h4 className="font-semibold">{title}</h4>
      <div>{subtitle}</div>
      <div className="mt-5">
        <Button
          size="md"
          variant={ButtonVariant.PrimaryBlack}
          rightIcon={<ChevronRight size={16} />}
          onClick={openInNewTab}
        >
          {stringButtonText}
        </Button>
      </div>
    </div>
  );
};
export default UseCaseCTA;
