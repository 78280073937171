import React from "react";
import UseCaseStep from "./UseCaseStep";
import { UseCaseStepType } from "types/UseCaseWorkflowTypes";
type UseCaseStepsProps = {
  steps: UseCaseStepType[];
};
const UseCaseSteps = ({ steps }: UseCaseStepsProps) => {
  const content = steps.map((step: any, index: number) => {
    const {
      name,
      content,
      feature_callout,
      step_image,
      reference_workflow_name,
      reference_workflow_slug,
    } = step;
    return (
      <UseCaseStep
        stepNumber={index + 1}
        name={name}
        content={content}
        featureCallouts={feature_callout}
        stepImage={step_image}
        reference_workflow_name={reference_workflow_name}
        reference_workflow_slug={reference_workflow_slug}
      />
    );
  });
  return <>{content}</>;
};
export default UseCaseSteps;
