import React from "react";
import styled from "styled-components";
import { Badge } from "@merge-api/merge-javascript-shared";
import { CategorySubcategory } from "types/UseCaseWorkflowTypes";
const FlexContainer = styled.div`
  gap: 12px;
`;
type UseCaseWorkflowTitleProps = {
  title: string;
  description: string;
  categories: CategorySubcategory[];
};
const UseCaseWorkflowTitle = ({ title, description, categories }: UseCaseWorkflowTitleProps) => {
  return (
    <div>
      <FlexContainer className="d-flex align-items-center">
        <h2 className="mb-0">{title}</h2>

        {categories && categories.length > 0 && (
          <FlexContainer className="d-flex">
            {categories[0]?.category && (
              <Badge size="lg" color="blue">
                {categories[0]?.category}
              </Badge>
            )}
            {categories[0]?.subcategory &&
              categories[0]?.subcategory.length > 0 &&
              categories[0]?.subcategory.map((subcategory) => {
                return (
                  <Badge size="lg" color="blue">
                    {subcategory}
                  </Badge>
                );
              })}
          </FlexContainer>
        )}
      </FlexContainer>
      <div className="mt-4">{description}</div>
    </div>
  );
};

export default UseCaseWorkflowTitle;
