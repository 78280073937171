import React, { useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import UseCaseWorkflowTitle from "./components/UseCaseWorkflowTitle";
import UseCaseSteps from "./components/UseCaseSteps";
import UseCaseModelsAndFields from "./components/UseCaseModelsAndFields";
import SupportedIndustries from "./components/SupportedIndustries";
import SyncFrequency from "./components/SyncFrequency";
import UseCaseCTA from "./components/UseCaseCTA";
import RelatedUseCases from "./components/RelatedUseCases";
import CaseStudies from "./components/CaseStudies";
import FeedbackButton from "components/docs/shared-components/FeedbackButton";
import {
  UseCaseType,
  UseCaseStepType,
  UseCaseCTAType,
  CaseStudyType,
  RelatedUseCaseWorkflowType,
} from "types/UseCaseWorkflowTypes";
import { navigate } from "gatsby";

const UseCaseInfoContainer = styled.div`
  gap: 24px;
`;

const RightCol = styled(Col)`
  &&& {
    @media (max-width: 990px) {
      margin-top: 24px;
      padding-top: 4px;
      padding-left: 12px !important;
      padding-right: 4px;
    }
    padding-left: 60px !important;
  }
`;

type UseCaseWorkflowPageProps = {
  pageContext: {
    workflowId: string;
    useCase: UseCaseType;
    useCaseSteps: UseCaseStepType[];
    useCaseCTA: UseCaseCTAType;
    caseStudies: CaseStudyType[];
    relatedUseCases: RelatedUseCaseWorkflowType[];
    publishedState: string;
  };
};

const UseCaseWorkflowPage = ({ pageContext }: UseCaseWorkflowPageProps) => {
  const {
    useCase: {
      name,
      description,
      use_case_models: useCaseModelsAndFields,
      industries,
      categories,
      recommended_sync_frequency: recommendedSyncFrequency,
      use_case_features: useCaseFeatures,
    },
    workflowId,
    useCaseSteps,
    relatedUseCases,
    useCaseCTA,
    caseStudies,
    publishedState,
  } = pageContext;

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (publishedState === "STAGED" && !window.location.search.includes("preview=true")) {
        navigate("/use-cases/");
      }
    }
  }, [publishedState]);

  return (
    <StaticPageContainer
      style={{
        paddingTop: "82px",
      }}
      lessPadding={true}
    >
      <Row>
        <Col lg={8}>
          <UseCaseWorkflowTitle title={name} description={description} categories={categories} />
          <div className="mt-9 mb-9">
            <hr />
          </div>
          <UseCaseSteps steps={useCaseSteps} />
          {useCaseCTA && <UseCaseCTA cta={useCaseCTA} />}
          <FeedbackButton use_case_workflow_id={workflowId} />
          {relatedUseCases.length > 0 && <RelatedUseCases relatedUseCases={relatedUseCases} />}
        </Col>
        <RightCol lg={4}>
          <UseCaseInfoContainer className="d-flex flex-column">
            {useCaseModelsAndFields && (
              <UseCaseModelsAndFields
                useCaseModelsAndFields={useCaseModelsAndFields}
                useCaseFeatures={useCaseFeatures}
              />
            )}
            {recommendedSyncFrequency && (
              <SyncFrequency recommendedSyncFrequency={recommendedSyncFrequency} />
            )}
            {industries.length > 0 && <SupportedIndustries industries={industries} />}
            {caseStudies && caseStudies.length > 0 && <CaseStudies caseStudies={caseStudies} />}
          </UseCaseInfoContainer>
        </RightCol>
      </Row>
    </StaticPageContainer>
  );
};

export default UseCaseWorkflowPage;
